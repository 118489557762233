import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import React from 'react'

function SquareLogoBlack({ rx, ...restProps }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 48 48" {...restProps}>
      <rect fill="white" height="48" rx="4" width="48" />

      <rect
        fill="url(#squareLogoBlack-paint0_linear)"
        height="48"
        rx={rx}
        width="48"
      />

      <path
        clipRule="evenodd"
        d="M31.5237 16.0867L32.3395 13.1872L28.153 11.4503C28.2545 10.943 28.3071 10.4306 28.3598 9.91826C28.4049 9.47917 28.4256 9.03756 28.4464 8.59596L28.4464 8.59576L32.8792 7.6952L32.6457 4.68788L28.1304 4.47047C27.9573 3.51576 27.7303 2.60484 27.4301 1.68639L30.1691 0H18.6386C20.6119 2.52741 21.6579 5.79014 21.3267 9.24488C20.6704 16.3522 14.339 21.5697 7.18386 20.8837C4.42643 20.6195 1.9549 19.5108 0 17.838V29.3517L1.64641 26.7745C2.50236 27.0843 3.41463 27.326 4.32941 27.5433L4.46816 32.1928L7.47225 32.4768L8.48044 27.9452C9.41778 27.9429 10.3626 27.8674 11.2637 27.7381L12.9656 32.0551L15.8798 31.2943L15.2867 26.7461C16.1834 26.4191 17.0364 26.0383 17.8724 25.5818L20.9166 29.0751L23.4111 27.359L21.3131 23.2972C22.0421 22.6817 22.7249 22.0369 23.3639 21.3382L27.3807 23.5262L29.1376 21.0685L25.8042 17.9892C26.2824 17.1754 26.6899 16.3297 27.0536 15.4302L31.5237 16.0867ZM40.6918 38.7016C41.1324 38.02 41.444 37.2725 41.6355 36.4871L44.5921 36.1128L44.5837 33.7146L41.6515 33.3577C41.4817 32.5971 41.173 31.8353 40.7341 31.1467L42.5679 28.7819L40.8596 27.0737L38.5022 28.9158C37.8296 28.503 37.1006 28.2008 36.3336 28.0187L35.9506 24.9879L33.534 24.987L33.1669 28.0302C32.4248 28.2094 31.7188 28.5 31.0489 28.902L28.5829 26.9933L26.8747 28.7017L28.7717 31.1336C28.3683 31.7877 28.0755 32.4983 27.8938 33.219L24.7889 33.6106L24.7974 36.0088L27.859 36.3853C28.0198 37.118 28.3104 37.8241 28.7124 38.4939L26.8131 40.9415L28.5215 42.6497L30.9161 40.7801C31.5793 41.2114 32.3083 41.5135 33.0475 41.7047L33.4304 44.7354L35.847 44.7364L36.2228 41.7673C36.9834 41.5975 37.7357 41.3073 38.415 40.8869L40.7797 42.7207L42.4879 41.0123L40.6918 38.7016ZM37.4693 37.5923C35.9851 39.0896 33.5685 39.0886 32.0713 37.6044C30.574 36.1201 30.575 33.7036 32.0592 32.2063C33.5435 30.7091 35.96 30.71 37.4757 32.2037C38.9545 33.6785 38.972 36.1045 37.4693 37.5923Z"
        fill="white"
        fillRule="evenodd"
      />

      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="squareLogoBlack-paint0_linear"
          x1="24"
          x2="24"
          y1="48"
          y2="-1.2"
        >
          <stop stopColor="#3D3D3D" />

          <stop offset="1" stopColor="#666666" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

SquareLogoBlack.propTypes = {
  rx: PropTypes.number,
}

export default SquareLogoBlack
