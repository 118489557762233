import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import PropTypes from 'prop-types'
import React from 'react'

function SquareLogoYellow({ rx, ...restProps }: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 48 48" {...restProps}>
      <rect
        fill="url(#squareLogoYellow-paint0_linear)"
        height="48"
        rx={rx}
        width="48"
      />

      <path
        clipRule="evenodd"
        d="M31.5237 16.0867L32.3395 13.1872L28.1531 11.4503C28.2545 10.943 28.3072 10.4306 28.3598 9.91826C28.4049 9.47917 28.4257 9.03756 28.4464 8.59596L28.4464 8.59576L32.8792 7.6952L32.6457 4.68788L28.1305 4.47047C27.9573 3.51576 27.7303 2.60484 27.4301 1.68639L30.1692 0H18.6387C20.612 2.52741 21.6579 5.79014 21.3267 9.24488C20.6704 16.3522 14.339 21.5697 7.1839 20.8837C4.42645 20.6195 1.95491 19.5108 0 17.838V29.3517L1.64645 26.7745C2.5024 27.0843 3.41467 27.326 4.32945 27.5433L4.4682 32.1928L7.47229 32.4768L8.48048 27.9452C9.41782 27.9429 10.3627 27.8674 11.2638 27.7381L12.9656 32.0551L15.8798 31.2943L15.2867 26.7461C16.1835 26.4191 17.0364 26.0383 17.8725 25.5818L20.9167 29.0751L23.4111 27.359L21.3131 23.2972C22.0422 22.6817 22.725 22.0369 23.364 21.3382L27.3808 23.5262L29.1376 21.0685L25.8042 17.9892C26.2824 17.1754 26.6899 16.3297 27.0536 15.4302L31.5237 16.0867ZM40.6918 38.7016C41.1325 38.02 41.4441 37.2725 41.6356 36.4871L44.5922 36.1128L44.5837 33.7146L41.6515 33.3577C41.4818 32.5971 41.1731 31.8353 40.7342 31.1467L42.568 28.7819L40.8596 27.0737L38.5023 28.9158C37.8297 28.503 37.1007 28.2008 36.3336 28.0187L35.9507 24.9879L33.5341 24.987L33.167 28.0302C32.4248 28.2094 31.7188 28.5 31.0489 28.902L28.5829 26.9933L26.8748 28.7017L28.7718 31.1336C28.3684 31.7877 28.0756 32.4983 27.8939 33.219L24.789 33.6106L24.7974 36.0088L27.8591 36.3853C28.0199 37.118 28.3105 37.8241 28.7125 38.4939L26.8132 40.9415L28.5216 42.6497L30.9162 40.7801C31.5793 41.2114 32.3084 41.5135 33.0475 41.7047L33.4305 44.7354L35.8471 44.7364L36.2228 41.7673C36.9834 41.5975 37.7357 41.3073 38.415 40.8869L40.7798 42.7207L42.488 41.0123L40.6918 38.7016ZM37.4694 37.5923C35.9852 39.0896 33.5686 39.0886 32.0714 37.6044C30.5741 36.1201 30.5751 33.7036 32.0593 32.2063C33.5435 30.7091 35.9601 30.71 37.4758 32.2037C38.9546 33.6785 38.9721 36.1045 37.4694 37.5923Z"
        fill="white"
        fillRule="evenodd"
      />

      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="squareLogoYellow-paint0_linear"
          x1="24"
          x2="24"
          y1="48"
          y2="-1.2"
        >
          <stop stopColor="#A87913" />

          <stop offset="1" stopColor="#FFC230" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

SquareLogoYellow.propTypes = {
  rx: PropTypes.number,
}

export default SquareLogoYellow
